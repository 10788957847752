body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background:#f7f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button{
  margin: 10px;
}

.blue-color-background{
  background: #3B5998;
}

.blue-color-accent{
  color:#3B5998;
}

.blue-color-accent2{
  color:#8B9DC3;
}

.logo{
  margin-left: 12px;
}

form h5, h5{
  font-weight: bold;
}

input[type=text]:not(.browser-default):focus:not([readonly]),
input[type=email]:not(.browser-default):focus:not([readonly]),
input[type=password]:not(.browser-default):focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]){
  border-color:  #8B9DC3;
  box-shadow: none;
}

.button-blue{
  background: #8B9DC3;
}

.blue-hover:hover{
  background: #8B9DC3;
}




.card-image{
  margin-top: 32px;
  max-height: 400px;
  overflow:hidden;
}

.notifications{
  margin-top: 32px;
  margin-right: 108px;
}

.name-notifications{
  font-weight: bold;
}

.boy-name{
  font-weight: bold;
}


.title-sub-notifications{
  font-weight: bold;
}

.bold{
  font-weight: bold;
}


.boy-section{
  margin-left: 32px;
}

.dashboard-girl{
  margin-top: 32px;
}

.carousel{
  margin-top: 32px;
}

.change-profile-picture{
  margin-top:16px;
}

.margin-right-icon{
  margin-right: 8px;
}

.intro-text{
  font-weight: bold;
  margin-top: 100px;
}

.Matches{
  margin-top: 32px;
}

.Conversations{
  margin-top: 32px;
}


form, table{
  padding: 20px;
  margin-top: 32px;
}

form button, form h5{
  margin: 20px 0;
}

h6{
  padding: 20px;
}

.title-landing-page{
  margin-top: 64px;
}

.text-landing-page {
  margin-bottom: -20px;
}

.header{
  margin-top: 32px;
}

.usage, .action{
  margin-top: 32px;
}

.nav-link-mobile-first{
  margin-top: 32px;
}

nav {
  height: 64px;
  line-height: 64px;
}

.menu{
  margin-top:4px;
}




/*
form button, form h5{
  margin: 20px 0;
}
*/
